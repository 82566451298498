import {
  Box,
  Button,
  FormControl,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';

import { TripDayEntity } from '../../../../shared/src/types/TripDayEntity';

interface TripDayEditorProps {
  tripDayEntity: TripDayEntity;
  onChange: (tripDay: TripDayEntity) => void;
  onEditClicked: () => void;
}

export default function TripDayEditor(props: TripDayEditorProps) {
  const [data, setData] = React.useState(props.tripDayEntity);
  const theme = useTheme();
  React.useEffect(() => {
    setData(props.tripDayEntity);
  }, [props.tripDayEntity]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    props.onChange(data);
  };

  return (
    <Paper
      sx={{
        my: 2,
        width: '100%', // Ensure the Box container uses full width of its parent
        boxSizing: 'border-box', // Ensure padding and borders are included in width calculation
      }}
      elevation={1}
    >
      {' '}
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" sx={{ padding: 2, width: '100%' }}>
          <Typography variant="h6">Edit Day</Typography>
          <TextField
            label="Notes"
            margin="normal"
            fullWidth
            multiline
            rows={4}
            value={data.notes || ''}
            onChange={handleChange}
            name="notes"
            autoComplete="off"
            sx={{ mt: 2, mb: 1 }} // Apply margin-top to create spacing between the rows
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 1,
              gap: 2, // Use gap for consistent spacing between buttons
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={props.onEditClicked}
            >
              Cancel
            </Button>{' '}
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Box>
        </FormControl>
      </form>
    </Paper>
  );
}
