import { AccomodationEntity } from '../../../shared/src/types/AccomodationEntity';
import { FlightEntity } from '../../../shared/src/types/FlightEntity';
import { LegEntity } from '../../../shared/src/types/LegEntity';
import { CarRentalEntity } from '../../../shared/src/types/RentalCarEntity';
import { TripDayEntity } from '../../../shared/src/types/TripDayEntity';
import { WaypointEntity } from '../../../shared/src/types/WaypointEntity';
import { useRoadbookContext } from '../context/RoadbookContext';

interface ListItem {
  id: string;
  isAfter?: string[];
}

function sortList<T extends ListItem>(list: T[]): T[] {
  const itemMap = new Map<string, T>();
  const afterMap = new Map<string, string>();
  const firstElement = new Set<string>();

  list.forEach((item) => {
    itemMap.set(item.id, item);
    firstElement.add(item.id);
    if (item.isAfter) {
      afterMap.set(item.id, item.isAfter[0]);
      firstElement.delete(item.id);
    }
  });

  return [];
}

export const useSelectors = () => {
  const { state } = useRoadbookContext();

  const selectOrderedWaypoints = (): WaypointEntity[] => {
    const waypointsState = state.waypoints;
    const waypoints = waypointsState.allIDs
      .map((id) => waypointsState.byID[id])
      .filter((s) => !s.deleted);

    if (waypoints.length === 0) {
      return [];
    }

    const idsSet = new Set<string>(waypoints.map((d) => d.id));
    const afterMap = new Map<string, string>();
    waypoints.forEach((d: WaypointEntity) => {
      const isAfter = d.is_after;
      if (isAfter && idsSet.has(isAfter)) {
        afterMap.set(d.id, isAfter);
        idsSet.delete(isAfter);
      }
    });

    if (idsSet.size > 1) {
      console.warn('Incorrect chain of waypoint items', idsSet);
      return waypoints;
    }

    const sortedIdList: string[] = Array.from(idsSet);
    let currentID: string | undefined = sortedIdList[0];
    while (currentID) {
      const next = afterMap.get(currentID);
      if (next) {
        sortedIdList.push(next);
      }
      currentID = next;
    }

    return sortedIdList.reverse().map((id) => waypointsState.byID[id]);
  };

  const selectDays = (): TripDayEntity[] => {
    const days =
      state.trip_days.allIDs.map((id) => state.trip_days.byID[id]) || [];
    return days.sort((a, b) => (a.index > b.index ? 1 : -1));
  };

  const selectAccomodationsByWaypointID = (
    waypointID: string,
  ): AccomodationEntity[] => {
    const accomodationIDs =
      state.accomodations.idsBy.waypointID[waypointID] || [];
    return accomodationIDs.map((id) => state.accomodations.byID[id]);
  };

  const selectwaypointByID = (waypointID: string): WaypointEntity => {
    return state.waypoints.byID[waypointID];
  };

  const selectFlightsBywaypointID = (waypointID: string): FlightEntity[] => {
    const flightIDs = state.flights.idsBy.waypointID[waypointID] || [];
    return flightIDs.map((id) => state.flights.byID[id]);
  };
  const selectCarRentalsBywaypointID = (
    waypointID: string,
  ): CarRentalEntity[] => {
    const carRentalIDs = state.rentalCars.idsBy.waypointID[waypointID] || [];
    return carRentalIDs.map((id) => state.rentalCars.byID[id]);
  };

  const selectLegForWaypointID = (
    waypointID: string,
  ): LegEntity | undefined => {
    const legsID = state.legs.idsBy['waypointID'][waypointID] || [];
    const legs = legsID.map((id) => state.legs.byID[id]);
    return legs.length > 0 ? legs[0] : undefined;
  };

  return {
    selectAccomodationsByWaypointID,
    selectCarRentalsBywaypointID,
    selectDays,
    selectFlightsBywaypointID,
    selectLegForWaypointID,
    selectOrderedWaypoints,
    selectwaypointByID,
  };
};
