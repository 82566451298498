import { Box, Button } from '@mui/material';
import React from 'react';

import { TripDayEntity } from '../../../../shared/src/types/TripDayEntity';
import {
  actionCreateWaypoint,
  actionUpdateTripDay,
} from '../../actions/actions';
import { useRoadbookContext } from '../../context/RoadbookContext';
import { useSelectors } from '../../hooks/selectors';
import useOrderedWaypointList from '../Trip/orderedWaypointList';
import Waypoint from '../Waypoint/Waypoint';
import WaypointCreateButton from '../Waypoint/WaypointCreateButton';
import TripDayBar from './TripDayBar';
import TripDayEditor from './TripDayEditor';
import TripDayNotes from './TripDayNotes';

interface TripDayProps {
  tripDay: TripDayEntity;
}

export default function TripDay(props: TripDayProps) {
  const { dispatchAction } = useRoadbookContext();
  const [edit, setEdit] = React.useState(false);
  const { selectOrderedWaypoints } = useSelectors();
  const { lastWaypointBeforeDayID } = useOrderedWaypointList();

  const waypoints = selectOrderedWaypoints().filter(
    (wp) => wp.day_id === props.tripDay.id,
  );

  const handleCreateWaypoint = () => {
    const isAfter = lastWaypointBeforeDayID(props.tripDay.id);
    console.log('isAfter', isAfter);
    dispatchAction(actionCreateWaypoint(props.tripDay.id, isAfter));
  };

  return (
    <>
      {edit ? (
        <TripDayEditor
          tripDayEntity={props.tripDay}
          onChange={(tripDay) => {
            dispatchAction(actionUpdateTripDay(tripDay));
            setEdit(false);
          }}
          onEditClicked={() => setEdit(!edit)}
        />
      ) : (
        <>
          <TripDayBar
            tripDayEntity={props.tripDay}
            onEditClicked={() => setEdit(!edit)}
          />
          <TripDayNotes value={props.tripDay} />
        </>
      )}
      {waypoints.map((waypoint, index) => (
        <Waypoint key={index} waypoint={waypoint} />
      ))}
      {waypoints.length === 0 && (
        <WaypointCreateButton onCreate={handleCreateWaypoint} />
      )}
    </>
  );
}
