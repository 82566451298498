import React from 'react';
import { Box, Button } from '@mui/material';

interface TripAddProps {
  onCreate: () => void;
}

export default function TripAdd({ onCreate }: TripAddProps) {
  return (
    <Box sx={{ p: 1, mt: 1, textAlign: 'center' }}>
      <Button onClick={onCreate}>ADD DAY</Button>
    </Box>
  );
}
