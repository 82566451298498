import {
  Avatar,
  Card,
  CardContent,
  Container,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  getAccessToken,
  useSessionContext,
} from 'supertokens-auth-react/recipe/session';

import { useRoadbookContext } from '../context/RoadbookContext';

export default function UserProfile() {
  const { state, dispatchAction } = useRoadbookContext();
  const session = useSessionContext();
  const [displayToken, setDisplayToken] = React.useState('');
  const [isCopied, setIsCopied] = React.useState(false);

  React.useEffect(() => {
    const getToken = async () => {
      const token = await getAccessToken();
      if (token) {
        setDisplayToken(token);
      }
    };
    if (!session.loading && session.doesSessionExist) {
      getToken();
    }
  }, [session]);

  return (
    <Container maxWidth="lg">
      <Paper sx={{ mt: 2, p: 2, py: 4 }}>
        <Avatar
          sx={{ mx: 'auto', my: 4 }}
          alt="User Name"
          src={state.user.picture}
        />
        <Typography sx={{ mx: 'auto', textAlign: 'center', pb: 4 }}>
          {state.user.email}
        </Typography>

        <Card sx={{ mx: 'auto' }}>
          <CardContent>
            <TextField
              label="Access Token"
              value={displayToken}
              multiline
              onFocus={(event) => {
                event.target.select();
                navigator.clipboard.writeText(displayToken);
                setIsCopied(true);
              }}
              sx={{ width: '100%' }}
            ></TextField>
            {isCopied && (
              <Typography sx={{ mx: 'auto', textAlign: 'center', pt: 2 }}>
                Copied
              </Typography>
            )}
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
}
