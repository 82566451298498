import React from 'react';
import { TripDayEntity } from '../../../../shared/src/types/TripDayEntity';
import { Typography } from '@mui/material';

interface TripDayNotesProps {
  value: TripDayEntity;
}

export default function TripDayNotes({ value }: TripDayNotesProps) {
  return <Typography>{value.notes}</Typography>;
}
