import { Box, Button } from '@mui/material';
import React from 'react';

export default function WaypointCreateButton({
  onCreate,
}: {
  onCreate: () => void;
}) {
  return (
    <Box>
      <Button variant="contained" onClick={onCreate}>
        CREATE Waypoint
      </Button>
    </Box>
  );
}
