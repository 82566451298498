import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface MapsLinkProps {
  address: string;
  children: React.ReactNode;
  icon?: boolean;
}

export default function MapsLink({
  address,
  children,
  icon = true,
}: MapsLinkProps) {
  // link to google maps with the address
  const link = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
    address
  )}`;

  return (
    <a href={link} target="_blank">
      {icon && <FontAwesomeIcon icon="map-marker-alt" />} {children}{' '}
      <FontAwesomeIcon icon="external-link-alt" />
    </a>
  );
}
