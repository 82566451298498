import React from 'react';

import { WaypointEntity } from '../../../../shared/src/types/WaypointEntity';
import { useSelectors } from '../../hooks/selectors';
import Accomodations from '../Accomodation/Accomodations';
import Address from '../Address/Address';
import Flights from '../Flight/Flights';
import Leg from '../Leg/Leg';
import Notes from '../Notes/Notes';
import RentalCars from '../RentalCar/RentalCars';
import WaypointHeader from './WaypointHeader';

interface WaypointProps {
  waypoint: WaypointEntity;
}

export default function Waypoint({ waypoint }: WaypointProps) {
  const {
    selectAccomodationsByWaypointID,
    selectFlightsBywaypointID,
    selectCarRentalsBywaypointID,
    selectLegForWaypointID,
  } = useSelectors();

  return (
    <div>
      <Leg leg={selectLegForWaypointID(waypoint.id)} time={waypoint.time} />

      <WaypointHeader waypoint={waypoint} />
      <div>
        <Address waypoint={waypoint} />
        <Notes notes={waypoint.notes} />
        <Accomodations values={selectAccomodationsByWaypointID(waypoint.id)} />
        <Flights values={selectFlightsBywaypointID(waypoint.id)} />
        <RentalCars values={selectCarRentalsBywaypointID(waypoint.id)} />
      </div>
    </div>
  );
}