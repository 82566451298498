import React from 'react';
import { CarRentalEntity } from '../../../../shared/src/types/RentalCarEntity';
import RentalCar from './RentalCar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WaypointContent from '../Waypoint/WaypointContent';

interface RentalCarsProps {
  values: CarRentalEntity[];
}

export default function RentalCars({ values }: RentalCarsProps) {
  if (values.length === 0) {
    return null;
  }
  return (
    <WaypointContent icon="car">
      {values.map((rentalCar, index) => (
        <RentalCar key={index} rentalCar={rentalCar} />
      ))}
    </WaypointContent>
  );
}
