import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import {
  signOut,
  useSessionContext,
} from 'supertokens-auth-react/recipe/session';

import { actionRetriveUserInfo } from '../../actions/users';
import { useRoadbookContext } from '../../context/RoadbookContext';
import { AsyncAction } from '../../types/AsyncAction';

export default function RoadbookAppBar() {
  const { state, dispatch, dispatchAction } = useRoadbookContext();
  const session = useSessionContext();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const hasSession = !session.loading && session.doesSessionExist;


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogout = async () => {
    handleCloseUserMenu();
    await signOut();
    navigate('/auth');
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleToggleMode = () => {
    dispatch({ type: 'TOGGLE_DARK_MODE' });
    handleCloseUserMenu();
  };

  const showAvatar = !session.loading && session.doesSessionExist;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  return (
    <AppBar position="static" sx={{ borderRadius: 0 }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <FontAwesomeIcon
            icon="road"
            style={{ marginRight: '16px', cursor: 'pointer' }}
            size="2x"
            onClick={() => navigate('/')}
          />
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', sm: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/')}
          >
            Carnet de Route
          </Typography>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', sm: 'none' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/')}
          >
            <Link to="/trips">RB</Link>
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <IconButton onClick={handleToggleMode}>
              <FontAwesomeIcon
                color={state.app.darkMode !== prefersDarkMode ? '#666' : '#333'}
                size="xs"
                icon={state.app.darkMode !== prefersDarkMode ? 'sun' : 'moon'}
              />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {showAvatar && (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={state.user.email} src={state.user.picture} />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={() => {
                  navigate('/profile');
                  handleCloseUserMenu();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon="user" />
                </ListItemIcon>
                <Typography textAlign="center">User Profile</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <FontAwesomeIcon icon="sign-out-alt" />
                </ListItemIcon>
                <Typography textAlign="center">Sign-out</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  console.log(state);
                  handleCloseUserMenu();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon="terminal" />
                </ListItemIcon>
                <ListItemText>Log State</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}