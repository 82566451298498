import { Box, CircularProgress, Container } from '@mui/material';
import React, { useEffect } from 'react';



import { WaypointEntity } from '../../../../shared/src/types/WaypointEntity';
import {
  actionCreateDayWithIndex,
  actionLoadAccomodations,
  actionLoadFlights,
  actionLoadLegs,
  actionLoadRentalCars,
  actionLoadTripDays,
  actionLoadTrips,
  actionLoadWaypoints,
} from '../../actions/actions';
import { useRoadbookContext } from '../../context/RoadbookContext';
import { TripLeg } from '../../context/initialState';
import { useSelectors } from '../../hooks/selectors';
import TripDay from '../Day/TripDay';
import TripAdd from './TripAdd';
import useOrderedWaypointList from './orderedWaypointList';
import './trip.css';


const selectWaypointLegs = (waypoints: WaypointEntity[]): TripLeg[] => {
  const legs: TripLeg[] = [];
  let start_address: string | undefined;
  waypoints.forEach((waypoint) => {
    if (waypoint.address) {
      if (start_address) {
        legs.push({
          start_address,
          end_address: waypoint.address,
          dest_waypoint_id: waypoint.id,
        });
      }
      start_address = waypoint.address;
    }
  });

  return legs;
};

export default function Trip({ tripID }: { tripID: string }) {
  const { state, dispatch, dispatchAction } = useRoadbookContext();
  const { selectDays } = useSelectors();
  const { getOrderedWaypointList } = useOrderedWaypointList();
  const [preloading, setPreloading] = React.useState<TripLeg[]>([]);
  const waypoints = getOrderedWaypointList();
  const legs = React.useMemo(() => selectWaypointLegs(waypoints), [waypoints]);

  React.useEffect(() => {
    dispatchAction(actionLoadTrips());
    dispatchAction(actionLoadTripDays());
    dispatchAction(actionLoadWaypoints());
    dispatchAction(actionLoadLegs());
    dispatchAction(actionLoadAccomodations());
    dispatchAction(actionLoadFlights());
    dispatchAction(actionLoadRentalCars());
  }, []);

  useEffect(() => {
    if (!state.legs.loaded) {
      return;
    }

    const preload = async () => {
      const data: TripLeg[] = [];
      for (const leg of legs) {
        if (
          preloading.find(
            (l) =>
              l.start_address === leg.start_address &&
              l.end_address === leg.end_address &&
              l.dest_waypoint_id === leg.dest_waypoint_id,
          )
        ) {
          continue;
        }

        const existingData = state.legs.allIDs
          .map((id) => state.legs.byID[id])
          .find(
            (stateLeg) =>
              stateLeg.start_address === leg.start_address &&
              stateLeg.end_address === leg.end_address &&
              stateLeg.waypoint_id === leg.dest_waypoint_id,
          );

        if (!existingData) {
          data.push(leg);
        }
      }

      // Avoid making the same request multiple times
      setPreloading((prev) => [...prev, ...data]);
    };
    preload();
    console.log('legs', legs);
    return () => {};
  }, [legs, state.legs.loaded]);

  React.useEffect(() => {
    const request = async () => {
      const leg = preloading.pop();
      if (leg) {
        const response = await fetch('/api/v2/leg', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(leg),
        });
        const legEntity = await response.json();
        // Filter previous legs with same waypoint_id
        const existing = state.legs.allIDs
          .map((id) => state.legs.byID[id])
          .filter((l) => l.waypoint_id === legEntity.waypoint_id);

        existing.forEach((l) => {
          l.deleted = true;
        });

        dispatch({ type: 'ADD_LEGS', payload: [legEntity, ...existing] });
        setPreloading((prev) => prev.filter((l) => l !== leg));
      }
    };

    request();
  }, [preloading]);
  console.log(state);
  if (!state.waypoints.loaded || !state.legs.loaded) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: for overlay effect
          position: 'fixed', // Optional: for overlay effect
          top: 0, // Optional: for overlay effect
          left: 0, // Optional: for overlay effect
          width: '100%', // Optional: for overlay effect
        }}
      >
        <CircularProgress
          size={80}
          thickness={5}
          sx={{
            strokeLinecap: 'round', // Set the line cap to round
            '& .MuiCircularProgress-circle': {
              stroke: 'url(#gradient)',
              strokeLinecap: 'round', // Set the line cap to round
            },
          }}
        />
        <svg width="0" height="0">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop
                offset="0%"
                style={{ stopColor: '#FF5F6D', stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: '#FFC371', stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
        </svg>
      </Box>
    );
  }

  const days = selectDays().filter(
    (day) =>
      state.app.selectedDayIndex.length === 0 ||
      state.app.selectedDayIndex.includes(day.index),
  );

  return (
    <Container>
      {days.map((day) => (
        <TripDay tripDay={day} key={day.id} />
      ))}

      <TripAdd
        onCreate={() => dispatchAction(actionCreateDayWithIndex(days.length))}
      />
    </Container>
  );
}