import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import React from 'react';
import { useParams } from 'react-router-dom';



import { TripDayEntity } from '../../../../shared/src/types/TripDayEntity';
import { useRoadbookContext } from '../../context/RoadbookContext';


interface TripDayBarProps {
  tripDayEntity: TripDayEntity;
  onEditClicked: () => void;
}

export default function TripDayBar(props: TripDayBarProps) {
  const { id } = useParams<{ id: string }>();
  const { state } = useRoadbookContext();

  if (!id) {
    return <div>Invalid trip ID</div>;
  }
  const trip = state.trips.byID[id];

  if (!trip) {
    return <div>Chargement...</div>;
  }

  const date = new Date(trip.start_date);
  date.setDate(date.getDate() + props.tripDayEntity.index);

  const printDate = format(date, 'EEEE dd MMMM yyyy', { locale: fr });
  const capitalizedDate =
    printDate.charAt(0).toUpperCase() + printDate.slice(1);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'baseline',
      }}
    >
      <Typography variant="h5" sx={{ my: 2, mx: 0 }}>
        {capitalizedDate}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          flexGrow: 1,
          color: 'grey',
          fontSize: '18px',
          mx: 1,
        }}
      >
        Jour {props.tripDayEntity.index + 1}
      </Typography>
      <IconButton sx={{ fontSize: '16px' }} onClick={props.onEditClicked}>
        <FontAwesomeIcon icon="edit" />
      </IconButton>
    </Box>
  );
}