import WaypointContent from '../Waypoint/WaypointContent';

interface PlacesProps {
  notes: String;
}

export default function Notes({ notes }: PlacesProps) {
  if (notes.trim() === '') {
    return null;
  }
  return <WaypointContent icon="note-sticky">{notes}</WaypointContent>;
}
