import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Divider, FormControl, IconButton, List, ListItemIcon, ListItemText, Menu, MenuItem, Paper, TextField, Typography } from '@mui/material';
import React from 'react';



import { WaypointEntity } from '../../../../shared/src/types/WaypointEntity';
import {
  actionCreateWaypoint,
  actionDeleteWaypoint,
  actionUpdateWaypoint,
} from '../../actions/actions';
import { useRoadbookContext } from '../../context/RoadbookContext';


interface WaypointHeaderProps {
  waypoint: WaypointEntity;
}

export default function WaypointHeader({ waypoint }: WaypointHeaderProps) {
  const [editWaypoint, setEditWaypoint] = React.useState(false);
  const [editedWaypooint, setEditedWaypoint] =
    React.useState<WaypointEntity>(waypoint);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    setEditedWaypoint(waypoint);
  }, [waypoint]);

  const {
    state: {
      app: { editMode },
      trip_days,
    },
    dispatch,
    dispatchAction,
  } = useRoadbookContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateWaypoint = () => {
    dispatchAction(actionCreateWaypoint(waypoint.day_id, waypoint.id));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedWaypoint((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatchAction(actionUpdateWaypoint(editedWaypooint));
    setEditWaypoint(false);
  };

  const handleDelete = () => {
    dispatchAction(actionDeleteWaypoint(waypoint.id));
    setEditWaypoint(false);
  };

  const move = (
    <>
      <div className="waypoint_header__controls">
        <IconButton>
          <FontAwesomeIcon icon="arrow-up" size="2xs" />
        </IconButton>
      </div>
      <div className="waypoint_header__controls">
        <IconButton>
          <FontAwesomeIcon icon="arrow-down" size="2xs" />
        </IconButton>
      </div>
    </>
  );

  const editControls = (
    <>
      <div className="waypoint_header__controls">
        <IconButton onClick={handleClick}>
          <FontAwesomeIcon icon="add" size="2xs" />
        </IconButton>
        <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
          <MenuItem>
            <ListItemIcon>
              <FontAwesomeIcon icon="bed" />
            </ListItemIcon>
            <ListItemText>Add Accomodation</ListItemText>
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <FontAwesomeIcon icon="plane" />
            </ListItemIcon>
            <ListItemText>Add Flight</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <FontAwesomeIcon icon="car" />
            </ListItemIcon>
            <ListItemText>Add RentalCar</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleCreateWaypoint();
              handleClose();
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon="add" />
            </ListItemIcon>
            <ListItemText>New Waypoint</ListItemText>
          </MenuItem>
        </Menu>
      </div>
    </>
  );

  const editor = (
    <Box
      sx={{
        width: '100%', // Ensure the Box container uses full width of its parent
        boxSizing: 'border-box', // Ensure padding and borders are included in width calculation
      }}
    >
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" sx={{ padding: 2, width: '100%' }}>
          <Typography variant="h6">Edit Waypoint</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2, // Use gap for consistent spacing
            }}
          >
            <TextField
              label="Time"
              margin="normal"
              value={editedWaypooint.time}
              onChange={handleChange}
              name="time"
              autoFocus
              onFocus={(event) => {
                event.target.select();
              }}
            />
            <TextField
              label="Name"
              margin="normal"
              fullWidth
              onChange={handleChange}
              name="name"
              autoComplete="off"
              value={editedWaypooint.name}
            />
          </Box>
          <TextField
            label="Address"
            margin="normal"
            fullWidth
            value={editedWaypooint.address}
            onChange={handleChange}
            name="address"
            autoComplete="off"
            sx={{ mt: 1 }}
          />
          <TextField
            label="Notes"
            margin="normal"
            fullWidth
            multiline
            rows={4}
            value={editedWaypooint.notes || ''}
            onChange={handleChange}
            name="notes"
            autoComplete="off"
            sx={{ mt: 1 }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 1,
              gap: 1, // Use gap for consistent spacing between buttons
            }}
          >
            <Button variant="text" color="error" onClick={handleDelete}>
              Delete
            </Button>
            <Box
              sx={{
                display: 'flex',
                gap: 1, // Use gap for consistent spacing between buttons
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setEditedWaypoint(waypoint);
                  setEditWaypoint(false);
                }}
              >
                Cancel
              </Button>{' '}
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Box>
          </Box>{' '}
        </FormControl>
      </form>
    </Box>
  );

  return (
    <Paper
      elevation={3}
      className="roadbook_box"
      sx={{
        borderRadius: '10px',
      }}
    >
      {editWaypoint ? (
        editor
      ) : (
        <>
          <div className="roadbook_box__strong">
          {waypoint.time}
          </div>
          <div className="roadbook_box__title">{waypoint.name}</div>
          {editMode && editControls}
          <div className="roadbook_box__controls">
            <IconButton onClick={(_) => setEditWaypoint(!editWaypoint)}>
              <FontAwesomeIcon
                icon={editWaypoint ? 'save' : 'edit'}
                size="2xs"
              />
            </IconButton>
          </div>
        </>
      )}
    </Paper>
  );
}