import { WaypointEntity } from '../../../../shared/src/types/WaypointEntity';
import MapsLink from '../Schedule/MapsLink';
import WaypointContent from '../Waypoint/WaypointContent';

interface PlacesProps {
  waypoint: WaypointEntity;
}

export default function Address({ waypoint }: PlacesProps) {
  if (waypoint.address.trim() === '') {
    return null;
  }
  return (
    <WaypointContent icon="map-marker-alt">
      <MapsLink icon={false} address={waypoint.address}>
        {waypoint.address}
      </MapsLink>
    </WaypointContent>
  );
}
