import { Action } from '../../../shared/src/types/Action';
import { PlaceEntity } from '../../../shared/src/types/PlaceEntity';
import { TripDayEntity } from '../../../shared/src/types/TripDayEntity';
import { WaypointEntity } from '../../../shared/src/types/WaypointEntity';
import { AsyncAction } from '../types/AsyncAction';

export function actionLoadAccomodations(): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const response = await fetch('/api/v2/acc');
    const accomodations = await response.json();
    dispatch({ type: 'LOAD_ACCOMODATIONS', payload: accomodations });
  };
}

export function actionLoadFlights(): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const response = await fetch('/api/v2/flt');
    const flights = await response.json();
    dispatch({ type: 'LOAD_FLIGHTS', payload: flights });
  };
}

export function actionLoadLegs(): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const response = await fetch('/api/v2/leg');
    const legs = await response.json();
    dispatch({ type: 'LOAD_LEGS', payload: legs });
  };
}

export function actionLoadRentalCars(): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const response = await fetch('/api/v2/rnt');
    const rentalCars = await response.json();
    dispatch({ type: 'LOAD_RENTAL_CARS', payload: rentalCars });
  };
}
export function actionLoadTrips(): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const response = await fetch('/api/v2/trip');
    const trips = await response.json();
    dispatch({ type: 'LOAD_TRIPS', payload: trips });
  };
}

export function actionLoadTripDays(): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const response = await fetch('/api/v2/day');
    const days = await response.json();
    dispatch({ type: 'LOAD_DAYS', payload: days });
  };
}

export function actionLoadWaypoints(): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const response = await fetch('/api/v2/waypoint');
    const waypoints = await response.json();
    dispatch({ type: 'LOAD_WAYPOINTS', payload: waypoints });
  };
}

export function actionCreateWaypoint(
  dayID: string,
  isAfter: string | undefined = undefined,
): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const newWaypoint = await fetch('/api/v2/waypoint', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ dayID, isAfter }),
    });

    const waypoints = await newWaypoint.json();

    dispatch({ type: 'ADD_WAYPOINTS', payload: waypoints });
  };
}

export function actionDeleteWaypoint(waypointID: string): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const deleted = await fetch(`/api/v2/waypoint/${waypointID}`, {
      method: 'DELETE',
    });

    const json = await deleted.json();

    dispatch({ type: 'DELETE_WAYPOINTS', payload: json });
  };
}

export function actionUpdateWaypoint(waypoint: WaypointEntity): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const updated = await fetch(`/api/v2/waypoint/${waypoint.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(waypoint),
    });

    const json = await updated.json();

    dispatch({ type: 'UPDATE_WAYPOINTS', payload: [json] });
  };
}

export function actionUpdateTripDay(day: TripDayEntity): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const updatedDay = await fetch(`/api/v2/day/${day.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(day),
    });

    const json = await updatedDay.json();

    dispatch({ type: 'UPDATE_DAYS', payload: [json] });
  };
}

export function actionUpdatePlace(place: PlaceEntity): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const updatedPlace = await fetch(`/api/v2/place/${place.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(place),
    });

    const json = await updatedPlace.json();

    dispatch({ type: 'UPDATE_PLACES', payload: [json] });
  };
}

export function toggleEditMode(value: boolean | undefined = undefined) {
  return { type: 'TOGGLE_EDIT_MODE', payload: value };
}

export function actionCreateDayWithIndex(index: number): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const newDay = await fetch('/api/v2/day', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ index }),
    });

    const day = await newDay.json();

    dispatch({ type: 'ADD_DAYS', payload: [day] });
  };
}
