import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card, CardActionArea, CardActions, CardHeader, CardMedia, Container, Fab, Grid } from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import React from 'react';
import { useNavigate } from 'react-router-dom';



import { actionLoadTrips } from '../actions/actions';
import { useRoadbookContext } from '../context/RoadbookContext';



export default function Home() {
  const { state, dispatchAction } = useRoadbookContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatchAction(actionLoadTrips());
  }, []);

  const trips = state.trips.allIDs.map((id) => state.trips.byID[id]);
  if (trips.length === 0) {
    return;
  }
 

  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      <Grid container spacing={4}>
        {trips.map((trip) => {
          const formattedDate = format(trip.start_date, 'MMMM do, yyyy', {
            locale: fr,
          });
          return (
            <Grid key={trip.id} item xs={12} sm={6} lg={4}>
              <Card sx={{ maxWith: 345 }}>
                <CardActionArea
                  onClick={() => {
                    navigate(`/t/${trip.id}`);
                  }}
                >
                  <CardHeader title={trip.name} subheader={formattedDate} />
                  <CardMedia
                    component="img"
                    height="140"
                    image="images/isl1.jpg"
                  />
                </CardActionArea>
                <CardActions>
                  <Button size="small" onClick={(e) => {}}>
                    Share
                  </Button>
                  <Button size="small">Open</Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
        <Grid item xs={12} sm={6} lg={4}>
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              alignContent: 'center',

              textAlign: 'center',
            }}
          >
            {' '}
            <CardActionArea sx={{ height: '100%', minHeight: '150px', justifyContent:'center', alignItems:'center', display:'flex'  }}>
              <Box
                className="MuiButtonBase-root MuiFab-root MuiFab-extended MuiFab-extended MuiFab-default MuiFab-sizeLarge"
                sx={{
                  borderRadius: '24px',
                  height: '48px',
                  width: '48px',
                  bgcolor: 'action.disabledBackground',
              
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  margin: 0,
                  border:0,
                  position: 'relative',
                  verticalAlign: 'middle',
                  userSelect: 'none',
                  textDecoration: 'none',

                  
              
                }}
              >
                <FontAwesomeIcon icon="add" />
              </Box>

            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}