import { Action } from '../../../shared/src/types/Action';
import { normalize, normjoin } from '../utils/normalize';
import { RoadbookState } from './initialState';

function reducer(state: RoadbookState, action: Action): RoadbookState {
  try {
    // console.debug(action);
    const payload = action.payload;
    switch (action.type) {
      case 'TOGGLE_DARK_MODE': {
        return {
          ...state,
          app: {
            ...state.app,
            darkMode: !state.app.darkMode,
          },
        };
      }

      case 'RETRIEVE_USER_INFO': {
        return {
          ...state,
          user: {
            ...state.user,
            email: payload.email,
            picture: payload.picture,
          },
        };
      }

      case 'LOAD_TRIPS':
      case 'ADD_TRIPS':
      case 'UPDATE_TRIPS': {
        let newState = {
          ...state,
          trips: normalize(state.trips, payload),
        };
        return newState;
      }

      case 'LOAD_LEGS':
      case 'UPDATE_LEGS':
      case 'ADD_LEGS': {
        let newState = {
          ...state,
          legs: normalize(state.legs, payload),
        };
        newState = normjoin(newState, payload, {
          fields: ['id', 'waypoint_id'],
          objects: [{ name: 'legs' }, { name: 'waypoints' }],
          keys: ['legID', 'waypointID'],
        });

        return newState;
      }

      case 'LOAD_WAYPOINTS':
      case 'ADD_WAYPOINTS':
      case 'DELETE_WAYPOINTS':
      case 'UPDATE_WAYPOINTS': {
        const newState = {
          ...state,
          waypoints: normalize(state.waypoints, payload, [
            {
              name: 'dayID',
              keys: ['day_id'],
            },
          ]),
        };
        return newState;
      }

      case 'SET_SELECTED_DAYS': {
        return {
          ...state,
          app: {
            ...state.app,
            selectedDayIndex: payload.selectedDays,
          },
        };
      }

      case 'TOGGLE_EDIT_MODE': {
        return {
          ...state,
          app: {
            ...state.app,
            editMode: payload != undefined ? payload : !state.app.editMode,
          },
        };
      }

      case 'TOGGLE_TRANSIT': {
        return {
          ...state,
          app: { ...state.app, showTransit: !state.app.showTransit },
        };
      }

      case 'LOAD_ACCOMODATIONS':
      case 'UPDATE_ACCOMODATIONS':
      case 'ADD_ACCOMODATIONS': {
        let newState = {
          ...state,
          accomodations: normalize(state.accomodations, payload),
        };

        newState = normjoin(newState, payload, {
          fields: ['id', 'waypoint_id'],
          objects: [{ name: 'accomodations' }, { name: 'waypoints' }],
          keys: ['accomodationID', 'waypointID'],
        });

        return newState;
      }

      case 'LOAD_DAYS':
      case 'ADD_DAYS':
      case 'UPDATE_DAYS': {
        let s = {
          ...state,
          trip_days: normalize(state.trip_days, payload),
        };
        s = normjoin(s, payload, {
          fields: ['id', 'trip_id'],
          objects: [{ name: 'trip_days' }, { name: 'trips' }],
          keys: ['tripDayID', 'tripID'],
        });
        return s;
      }

      case 'LOAD_FLIGHTS':
      case 'ADD_FLIGHTS':
      case 'UPDATE_FLIGHTS': {
        let newState = {
          ...state,
          flights: normalize(state.flights, payload),
        };
        newState = normjoin(newState, payload, {
          fields: ['id', 'waypoint_id'],
          objects: [{ name: 'flights' }, { name: 'waypoints' }],
          keys: ['flightID', 'waypointID'],
        });

        return newState;
      }

      case 'LOAD_RENTAL_CARS':
      case 'ADD_RENTAL_CARS':
      case 'UPDATE_RENTAL_CARS': {
        let newState = {
          ...state,
          car_rentals: normalize(state.rentalCars, payload),
        };
        newState = normjoin(newState, payload, {
          fields: ['id', 'waypoint_id'],
          objects: [{ name: 'rentalCars' }, { name: 'waypoints' }],
          keys: ['rentalCarID', 'waypointID'],
        });
        return newState;
      }

      // ==== DEFAULT ====
      default:
        throw new Error(
          `[${action.type}] is unknown in Torga Reducer. Did you returned a new state ?`,
        );
    }
  } catch (err) {
    console.error(err);
    return state;
  }
}

export default reducer;
