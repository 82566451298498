import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Icon,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { intervalToDuration } from 'date-fns';
import React from 'react';



import { LegEntity } from '../../../../shared/src/types/LegEntity';
import { useLegResolver } from '../../hooks/useLegResolver';
import { formattedTime } from '../../utils/time';



interface LegProps {
  leg: LegEntity | undefined;
  time?: string;
}

const calcDepartureTime = (
  duration: number,
  time: string | undefined,
): string | undefined => {
  if (!time) return '';

  const [hours, minutes] = time.split(':').map(Number);
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  // substract duration as seconds
  date.setSeconds(date.getSeconds() - duration);

  return formattedTime(date);
};

const secondsToDuration = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const { hours, minutes } = duration;
  let formattedDuration = '';
  if (hours) formattedDuration += `${hours}h`;
  if (minutes) formattedDuration += `${minutes}`;
  if (!hours && minutes) formattedDuration += ' min';

  return formattedDuration.trim();
};

const generateLink = (origin: string, destination: string): string => {
  const baseUrl = 'https://www.google.com/maps/dir/?api=1';
  const originParam = `origin=${encodeURIComponent(origin)}`;
  const destinationParam = `destination=${encodeURIComponent(destination)}`;
  return `${baseUrl}&${originParam}&${destinationParam}`;
};

export default function Leg({ leg, time }: LegProps) {
  const { resolve } = useLegResolver();
  const theme = useTheme();

  React.useEffect(() => {
    console.log('Recycle LEG');
    if (!leg) return;

    if (!leg.resolved) {
      resolve(leg.id);
    }
  }, [leg]);

  if (!leg) {
    return null;
  }

  return (
    <div>
      <Paper
        elevation={1}
        className="roadbook_box"
        sx={{
          borderRadius: '10px',
          boxShadow: 0,
        }}
      >
        <Box className="roadbook_box__strong">
          {calcDepartureTime(leg.duration, time) || (
            <FontAwesomeIcon icon="clock" style={{ color: 'orange' }} />
          )}
        </Box>
        <Box className="roadbook_box__title">Départ vers {leg.end_address}</Box>
      </Paper>
      <div className="flexible-row">
        <div className="waypoint__body">
          <div className="waypoint__spacer">
            <FontAwesomeIcon icon="road" />
          </div>

          <div className="waypoint__content">
            <Typography>
              {Intl.NumberFormat('fr', {
                useGrouping: true,
                style: 'unit',
                unit: 'kilometer',
                unitDisplay: 'short',
                maximumFractionDigits: 0,
              }).format(leg.distance / 1000)}
              {' | '}
              {secondsToDuration(leg.duration)}
              {' | '}
              {leg.summary}
            </Typography>
          </div>
        </div>
        {leg.resolved && (
          <img
            src={`/api/images/${leg.id}`}
            className="schedule__img"
            alt="map"
          />
        )}
      </div>
    </div>
  );
}