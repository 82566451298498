import { Box, SwipeableDrawer } from '@mui/material';
import React from 'react';

import RoadbookAppBar from '../../components/AppBar/RoadbookAppBar';
import Roadbook from '../../pages/Roadbook';

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <Box sx={{}}>
      <RoadbookAppBar />
      <Box>{children}</Box>
    </Box>
  );
}
