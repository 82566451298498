import React from 'react';

import { FlightEntity } from '../../../../shared/src/types/FlightEntity';
import Flight from './Flight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WaypointContent from '../Waypoint/WaypointContent';

interface FlightsProps {
  values: FlightEntity[];
}

export default function Flights({ values }: FlightsProps) {
  if (values.length === 0) {
    return null;
  }

  return (
    <WaypointContent icon="plane">
      {values.map((flight, index) => (
        <Flight key={index} flight={flight} />
      ))}
    </WaypointContent>
  );
}
