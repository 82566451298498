import React from 'react';

import { AccomodationEntity } from '../../../../shared/src/types/AccomodationEntity';
import Accomodation from './Accomodation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WaypointContent from '../Waypoint/WaypointContent';

interface AccomodationsProps {
  values: AccomodationEntity[];
}

export default function Accomodations({ values }: AccomodationsProps) {
  if (values.length === 0) {
    return null;
  }
  return (
    <WaypointContent icon="bed">
      {values.map((value, index) => (
        <Accomodation key={index} accomodation={value} />
      ))}
    </WaypointContent>
  );
}
