import { ThemeProvider } from '@emotion/react';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CssBaseline, GlobalStyles, Typography, createTheme, useMediaQuery } from '@mui/material';
import { indigo } from '@mui/material/colors';
import React from 'react';
import * as reactRouterDom from 'react-router-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { ThirdpartyComponentsOverrideProvider, getAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-auth-react/recipe/thirdparty';
import { ThirdPartyPreBuiltUI } from 'supertokens-auth-react/recipe/thirdparty/prebuiltui';
import { AuthRecipeComponentsOverrideContextProvider, getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';



import Offline from '../components/Offline/Offline';
import { useRoadbookContext } from '../context/RoadbookContext';
import Home from '../pages/Home';
import UserProfile from '../pages/Profile';
import Roadbook from '../pages/Roadbook';
import './App.css';
import Layout from './Layout/Layout';
import { SuperTokensConfig } from './config';
import './fontAwesomeSetup';

async function googleSignInClicked() {
  try {
    const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
      thirdPartyId: 'google',

      // This is where Google should redirect the user back after login or error.
      // This URL goes on the Google's dashboard as well.
      frontendRedirectURI: `${window.location.origin}/auth/callback/google`,
    });

    /*
        Example value of authUrl: https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&client_id=1060725074195-kmeum4crr01uirfl2op9kd5acmi9jutn.apps.googleusercontent.com&state=5a489996a28cafc83ddff&redirect_uri=https%3A%2F%2Fsupertokens.io%2Fdev%2Foauth%2Fredirect-to-app&flowName=GeneralOAuthFlow
        */

    // we redirect the user to google for auth.
    window.location.assign(authUrl);
  } catch (err: any) {
    if (err.isSuperTokensGeneralError === true) {
      // this may be a custom error message sent from the API by you.
      window.alert(err.message);
    } else {
      window.alert('Oops! Something went wrong.');
    }
  }
}

SuperTokens.init(SuperTokensConfig());

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const {
    state: {
      app: { darkMode },
    },
  } = useRoadbookContext();
  const useTheme = prefersDarkMode !== darkMode;

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: indigo,
          mode: useTheme ? 'dark' : 'light',
          background: {
            paper: useTheme ? '#121212' : '#eeeeee',
          },
        },
        components: {
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: useTheme ? '#121212' : '#eeeeee',
                borderRadius: 15,
              },
              elevation1: {
                boxShadow: 'none',
              },
              elevation3: {
                backgroundImage: useTheme
                  ? 'linear-gradient(0deg, #292929,  20%, #424242 100%)'
                  : 'linear-gradient(0deg, #eeeeee,  50%, #fafafa 100%)',
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                backgroundColor: useTheme ? '#333' : '#ffffff',
                borderRadius: 15,
              },
            },
          },
        },
      }),
    [useTheme],
  );

  const globalStyles = (
    <GlobalStyles
      styles={{
        h1: {
          fontSize: '40px',
        },
        body: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          margin: 0,
          padding: 0,
          fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
          touchAction: 'pan-y',
          overflowX: 'hidden',
        },
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
        },
        a: {
          textDecoration: 'none',
          color: theme.palette.text.primary,
        },
        ':root': {
          '--primary-color': theme.palette.primary.main,
          '--secondary-color': theme.palette.secondary
            ? theme.palette.secondary.main
            : theme.palette.primary.main,
          '[data-supertokens~=container]': {
            backgroundColor: theme.palette.background.paper,
          },
        },
        form: {
          width: '100%',
        },
        '.schedule__img': {
          filter: useTheme
            ? 'brightness(0.7) grayscale(0.2) contrast(1.5)'
            : '',
        },
      }}
    />
  );

  return (
    <div className="App">
      <Offline>
        <SuperTokensWrapper>
          <AuthRecipeComponentsOverrideContextProvider
            components={{
              AuthPageHeader_Override: ({ DefaultComponent, ...props }) => {
                return <div></div>;
              },
            }}
          >
            <ThirdpartyComponentsOverrideProvider
              components={{
                ThirdPartySignInAndUpProvidersForm_Override: ({
                  DefaultComponent,
                  ...props
                }) => {
                  // optionally override the third party providers list..
                  return (
                    <>
                      <Button
                        sx={{ textTransform: 'none' }}
                        variant="contained"
                        onClick={googleSignInClicked}
                        startIcon={<FontAwesomeIcon icon={faGoogle} />}
                      >
                        Continue With Google
                      </Button>
                    </>
                  );
                },
              }}
            >
              <BrowserRouter>
                <ThemeProvider theme={theme}>
                  <CssBaseline enableColorScheme />
                  {globalStyles}
                  <Layout>
                    <Routes>
                      {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
                        ThirdPartyPreBuiltUI,
                      ])}
                      <Route
                        path="/"
                        element={
                          <SessionAuth>
                            <Home />
                          </SessionAuth>
                        }
                      />
                      <Route
                        path="/t/:id"
                        element={
                          <SessionAuth>
                            <Roadbook />
                          </SessionAuth>
                        }
                      />
                      <Route path="/profile" element={<UserProfile />} />
                    </Routes>
                  </Layout>
                </ThemeProvider>
              </BrowserRouter>
            </ThirdpartyComponentsOverrideProvider>
          </AuthRecipeComponentsOverrideContextProvider>
        </SuperTokensWrapper>
      </Offline>
    </div>
  );
}

export default App;