export const formattedTime = (date: Date): string | undefined => {
  const formattedTime =
    date.toLocaleTimeString('fr-FR', {
      hour: '2-digit',
      minute: '2-digit',
    }) + ' max';

  return date instanceof Date && !isNaN(date.getTime())
    ? formattedTime
    : undefined;
};
