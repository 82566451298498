import React from 'react';
import { CarRentalEntity } from '../../../../shared/src/types/RentalCarEntity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function RentalCar({
  rentalCar,
}: {
  rentalCar: CarRentalEntity;
}) {
  return (
    <>
      <div>{rentalCar?.company}</div>
      <div>
        <div>Booking: {rentalCar.booking_id}</div>
        <div>Pick-up: {rentalCar.pick_up_time}</div>
        <div>Drop-off: {rentalCar.drop_off_time}</div>
      </div>
    </>
  );
}
