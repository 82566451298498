import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import React from 'react';



import { EntityState } from '../../../types/normalize';

export default function WaypointContent({
  children,
  icon,
}: {
  children: React.ReactNode;
  icon: IconProp;
}) {
  return (
    <div className="waypoint__body">
      <div className="waypoint__spacer">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="waypoint__content">
        <Typography component="div">{children}</Typography>
      </div>
    </div>
  );
}