import { useParams } from 'react-router-dom';

import Header from '../components/Header/Header';
import Trip from '../components/Trip/Trip';

export default function Roadbook() {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return <div>Invalid trip ID</div>;
  }

  return (
    <>
      <Header tripID={id} />
      <Trip tripID={id} />
    </>
  );
}
