import cx from 'classnames';
import { useEffect } from 'react';
import { useBooleanState } from 'webrix/hooks';

export default function Offline({ children }) {
  const {
    value: online,
    setFalse: setOffline,
    setTrue: setOnline,
  } = useBooleanState(navigator.onLine);

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, [setOnline, setOffline]);

  return (
    <>
      <div>
        <div style={{ display: online ? 'none' : void 0 }}>OFFLINE</div>
      </div>
      {children}
    </>
  );
}
