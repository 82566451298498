import React from 'react';

import {
  actionLoadAccomodations,
  actionLoadFlights,
  actionLoadLegs,
  actionLoadRentalCars,
  actionLoadTripDays,
  actionLoadTrips,
  actionLoadWaypoints,
} from '../actions/actions';
import { AsyncAction } from '../types/AsyncAction';
import { RoadbookStateType } from '../types/RoadbookContext';
import { initialState } from './initialState';
import reducer from './reducer';

const RoadbookContext = React.createContext<RoadbookStateType | undefined>(
  undefined,
);

interface AppProviderProps {
  children: React.ReactNode;
}

export const RoadbookContextProvider: React.FC<AppProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const dispatchAction = async (action: AsyncAction) => {
    await action(dispatch, () => state);
  };



  return (
    <RoadbookContext.Provider value={{ state, dispatch, dispatchAction }}>
      {children}
    </RoadbookContext.Provider>
  );
};

export const useRoadbookContext = (): RoadbookStateType => {
  const context = React.useContext(RoadbookContext);

  if (context === undefined) {
    throw new Error(
      'useRoadbookContext must be used within a RoadbookProvider',
    );
  }
  return context;
};
