import React from 'react';

import { useRoadbookContext } from '../context/RoadbookContext';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export function useLegResolver() {
  const { dispatch } = useRoadbookContext();
  const [resolving, setResolving] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (resolving.length === 0) {
      return;
    }

    const resolve = async () => {
      const next = resolving.pop();
      if (!next) {
        return;
      }

      const leg = await fetch(`/api/v2/leg/resolve`, {
        body: JSON.stringify({ id: next }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });

      const json = await leg.json();

      dispatch({ type: 'UPDATE_LEGS', payload: [json] });

      await delay(500);

      setResolving((prev: string[]) => prev.filter((id) => id !== next));
    };
    resolve();
  }, [resolving]);

  return {
    resolve: function (id: string) {
      if (resolving.includes(id)) {
        return;
      }
      setResolving((prev) => [...prev, id]);
    },
  };
}
