import React from 'react';



import { WaypointEntity } from '../../../../shared/src/types/WaypointEntity';
import { useRoadbookContext } from '../../context/RoadbookContext';
import { useSelectors } from '../../hooks/selectors';


export default function useOrderedWaypointList() {
  const { state, dispatch, dispatchAction } = useRoadbookContext();

  const { selectOrderedWaypoints } = useSelectors();

  const waypoints = React.useMemo(
    () => selectOrderedWaypoints(),
    [state.waypoints],
  );

  return {
    getOrderedWaypointList: () => waypoints,
    lastWaypointBeforeDayID: (dayID: string) => {
      // Get sorted list of days
      const sortedDays = state.trip_days.allIDs
        .map((id) => state.trip_days.byID[id])
        .sort((a, b) => a.index - b.index);
      //debugger;
      const currentDayIndex =
        sortedDays.find((day) => day.id === dayID)?.index || 0;
      // iterate through waypoints
      let lastWaypoint: WaypointEntity | undefined = undefined;
      for (const waypoint of waypoints) {
        const waypointDay = sortedDays.find(
          (day) => day.id === waypoint.day_id,
        );
        if (waypointDay && waypointDay?.index < currentDayIndex) {
          lastWaypoint = waypoint;
        }
      }
      return lastWaypoint?.id;
    },
  };
}