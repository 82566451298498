import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlightEntity } from '../../../../shared/src/types/FlightEntity';

export default function Flight({ flight }: { flight: FlightEntity }) {
  return (
    <>
      <div>
        {flight.airline} {flight.flight_number}
      </div>
      <div>
        <div>Départ : {flight.departure_time}</div>
        <div>Arrivée : {flight.arrival_time}</div>
      </div>
    </>
  );
}
