import { AccomodationEntity } from '../../../../shared/src/types/AccomodationEntity';

export default function Accomodation({
  accomodation,
}: {
  accomodation: AccomodationEntity;
}) {
  return (
    <>
      <div>{accomodation.name}</div>

      <div>Booking: {accomodation.booking_id} </div>
      <div>Check-in: {accomodation.check_in_time}</div>
      <div>Check-out: {accomodation.check_out_time}</div>
      <div>
        Phone: <a href={`tel:${accomodation.phone}`}>{accomodation.phone}</a>
      </div>
      <div>Email: {accomodation.mail}</div>
    </>
  );
}
